.hamburger {
   opacity:1!important;

   &:hover {
      opacity: 1;
   }
   .hamburger-inner,
   .hamburger-inner:before,
   .hamburger-inner:after {
      background: $blue;
   }
}

.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner::before, .hamburger.is-active .hamburger-inner::after {
   background: $blue;
}

.navbar {
   .nav-link {
      color:$blue;
      font-weight:bold;
   }
   .nav-item {
      position: relative;
      &.active:after,&:hover:after {
         height: 2px;
         background:$blue;
         content:"";
         position: absolute;
         bottom:0;
         left:0;
         right:0;
         @include breakpoint(lg) {
            bottom:0.5rem;
            left:0.5rem;
            right:0.5rem;
         }
      }
      @include breakpoint(lg) {
         margin-left:10px;
      }
      &.nav-item-search:after {
         display: none;
      }
   }
}

.navbar-collapse {
   padding:15px 0;
   @include breakpoint(lg) {
      padding:0;
   }
}

.search-icon {
   width:20px;
}

