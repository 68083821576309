.sub-nav-block {
   margin:15px 0;
   @include breakpoint(lg) {
      margin:30px 0;
   }
   .hamburger {
      // position: relative;
      // left:50%;
      // transform: translateX(-50%);
      &.sub-nav-btn {
         display: block;
         width:100%;
         background-color: $greybg;
         text-align: left;
         font-weight:bold;
         color:$blue;

         .hamburger-box {
            float: right;
            transform: scale(0.8);
         }
      }
   }
   .sub-nav {
      width:100%;
      position: relative;

      .sub-nav-item {
         color:$body-color;
         border-bottom:1px solid $verylightgrey;
         display: block;
         padding:5px 0;

         &:not(.active){
            //transition: 0.3s padding-left ease-in-out;
            &:hover {
               text-decoration: none;
               color:$blue;
               //padding-left:5px;
               border-bottom:1px solid $blue;
            }
         }
         &.active {
            font-weight:bold;
            position: relative;
            color:$blue;
            border-bottom-color:$blue;
            &:after {
               width:12px;
               height: 12px;
               position: absolute;
               right:0;
               top:50%;
               transform: translateY(-50%) rotate(0);
               content: "";
               background: url('/images/sub-nav-close.svg') no-repeat center;
               background-size: contain;
               transition: 0.3s transform ease-in-out;
            }
            &:hover {
               text-decoration: none;
               &:after {
                  transform: translateY(-50%) rotate(180deg);
               }
            }
         }
      }
   }
}