header.main-header {
   margin-bottom:30px;
   background-color: $greybg;
   &.hero-banner-active {
      position:absolute;
      top:0;
      left:0;
      right:0;
      z-index: 3;
      //background: rgba(#fff,0.8);
      background: rgba($greybg,0.7);
   }
   
   .logo {
      img {
         width:100px;
         height: 100%;
      }
   }
}

.search-form {
   .form-control {
      padding:20px;
      width:calc(100% - 46px);
      border:0;
      border-radius: 0.3rem;
   }
   .search-icon {
      height:100%;
      width:20px;
   }
}