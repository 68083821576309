.thumb-with-details {
   margin-bottom:15px;
   display: block;
   &:hover {
      text-decoration: none;
      h1 {
         padding-left:5px;
      }
      p {
         color: $body-color;
      }
   }
   @include breakpoint(lg) {
      margin-bottom:30px;
   }
   
   img {
      margin-bottom:5px;
   }
   h1 {
      margin-bottom:5px;
      padding-left:0;
      transition:0.3s padding-left ease-in-out;
   }
   p {
      margin-bottom:5px;
      color: $body-color;
   }
}