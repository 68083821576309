.thumbnail {
   margin-bottom:15px;
   position: relative;
   @include breakpoint(lg) {
      margin-bottom:30px;
   }
   h1 {
      margin-top:5px;
   }
   .award-status {
      position:absolute;
      z-index: 2;
      top:0;
      left:0;
      padding:10px 20px;
      text-transform: capitalize;
      background:rgba(#fff,0.7);
      color: $blue;
      font-weight: bold;
   }
}

a.thumbnail {
   display:block;
   text-decoration: none;
   color: $body-color;
   h1 {
      padding-left:0;
      transition:0.3s padding-left ease-in-out;
      font-weight: normal;
   }
   &:hover {
      h1 {
         padding-left:5px;
      }
   }
}