.hero-banner {
   height:90vh;
   position: relative;
   &:before {
      left:0;
      right:0;
      bottom:0;
      height: 30%;
      content: "";
      background: linear-gradient(0deg, rgba(0,0,0,0.49763655462184875) 0%, rgba(255,255,255,0) 100%);
      position:absolute;
      z-index: 2;
   }
   .carousel, .carousel .carousel-item, .carousel .carousel-inner {
      height: 100%;
   }
   .carousel-controls {
      position: absolute;
      top:0;
      right:0;
      bottom:0;
      left:0;
      z-index: 2;
      .container {
         position: relative;
         height:100%;
      }
      .carousel-control-prev {
         //left:15px;
         justify-content: flex-start;
      }
      .carousel-control-next {
         //right:15px;
         justify-content:flex-end;
      }
   }
   .carousel .carousel-item {
      transition-duration: 2s;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .carousel-control-prev-icon, .carousel-control-next-icon {
      background-image:url('/images/carousel-arrow.svg');
      width:60px;
      height: 60px;
      background-size:contain;
   }
   .carousel-control-prev-icon {
      transform:rotate(180deg);
   }
   .carousel-control-prev,.carousel-control-next {
      opacity: 1;
      transition: transform 0.3s ease-in-out;
      z-index: 2;
   }
   .carousel-control-prev:hover {
      transform:translateX(-15px);
   }
   .carousel-control-next:hover {
      transform:translateX(15px);
   }
   .hero-banner-footer {
      position: absolute;
      bottom:45px;
      left:0;
      right:0;
      z-index: 2;
      @include breakpoint(lg) {
         bottom:30px;
      }
      .scroll-arrow {
         width:60px;
         height:100%;
      }
      h1,h2 {
         color:#fff;
         span {
            font-weight:100;
         }
      }
   }
}