@mixin breakpoint($min: 0, $max: 0) {
  $type: type-of($min);
  
  @if $type == string {
    
    @if $min == sm {
      @media (min-width: 576px) { @content; }
    }
    
    @else if $min == md {
      @media (min-width: 768px) { @content; }
    }
    
    @else if $min == lg {
      @media (min-width: 992px) { @content; }
    }
    @else if $min == xl {
      @media (min-width: 1200px) { @content; }
    }
  
    // Otherwise pass a warning to the compiler as to the appropriate options
    @else {
      @warn "The breakpoint mixin supports the following attributes: xs, sm, md, lg";
    }
  }
  @else if $type == number {
    // Allow for custom parameters for min and max size
    $query: "all" !default;
    @if      $min != 0 and $max != 0 { $query: "(min-width: #{$min}) and (max-width: #{$max})"; } // set both min and max
    @else if $min != 0 and $max == 0 { $query: "(min-width: #{$min})"; } // set just min
    @else if $min == 0 and $max != 0 { $query: "(max-width: #{$max})"; } // set just max
    @media #{$query} { @content; }
  }
}