footer.main-footer {
   margin-top:30px;
   @include breakpoint(lg) {
      margin-top:60px;
   }

   .footer-content {
      background:$blue;
   }
   .footer-logo {
      width:60px;
      height:100%;
      @include breakpoint(lg) {
         width:80px;
      }
   }
   .social-icon {
      width:22px;
      height:100%;
      @include breakpoint(lg) {
         width:26px;
      }
   }
   .social-link {
      margin-left:15px;
   }
   .footer-header {
      padding:10px 0;
      border-bottom:1px solid #fff;
   }
   .footer-links-block {
      padding:30px 0;
      @include breakpoint(lg) {
         padding:60px 0;
      }
   }
   .footer-nav-header {
      color:#fff;
      margin-bottom:5px;
      @include breakpoint(lg) {
         margin-bottom:15px;
      }
   }
   .footer-nav {
      //font-size: $small;
      color:#fff;
      font-size:14px;
      margin-bottom:15px;
      @include breakpoint(lg) {
         margin-bottom:0;
      }
      a {
         color:#fff;
         border-bottom:1px solid $blue;
         font-weight:100;
         text-decoration: none;
         &:hover {
            border-bottom-color:#fff;
         }
      }
      ul {
         margin:0;
         li {
            margin-bottom:5px;
         }
      }
   }
   .footer-accreditations {
      padding:30px 0;
      text-align: center;
      @include breakpoint(lg) {
         padding:60px 0;
      }
      .accreditation-icon {
         max-width:60px;
         max-height: 60px;
         margin-bottom:10px;
         @include breakpoint(sm) {
            margin-bottom:0;
         }
         @include breakpoint(lg) {
            max-width:100px;
            max-height:100px;
         }
      }
   }
   .footer-copyright {
      border-top:1px solid $verylightgrey;
      padding:15px 0;
      //font-size:$small;
      text-align: center;
   }
}