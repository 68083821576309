// TYPE SCALE -------------------------------------------------------//

//Body text = major third (*1.25). Line height 1.5
//Headings = major third (*1.25). Line height 1.25

html,
body {
	font-size: 16px !important;
	line-height: 1.5;
   font-family: Arial, sans-serif;
   font-weight: 100;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6,.display-1,.display-2,.display-3,.display-4 {
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-webkit-text-stroke-width: 0.2px;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1.25;
	color:$blue;
	font-weight:bold;
	margin:0;
}
.display-1 {
	font-size:$display-1;
}
.display-2 {
	font-size:$display-2;
}
.display-3 {
	font-size:$display-3;
}
.display-4 {
	font-size:$display-4;
}
h1,.h1 {
	font-size:$h1;
}
h2,.h2 {
	font-size:$h2;
}
h3,.h3 {
	font-size:$h3;
}
h4,.h4 {
	font-size:$h4;
}

h5,.h5 {
	font-size:$h5;
}

h6,.h6 {
	font-size:$h6;
}

.lead {
	font-size:$lead;
}

.small,small {
	font-size:$small;
	font-weight:100;
}
