$display-1:6rem;
$display-2:4.76rem;
$display-3:3.81rem;
$display-4:3.05rem;
$h1:2.44rem;
$h2:1.95rem;
$h3:1.56rem;
$h4:1.25rem;
$h5:1rem;
$h6:0.8rem;
$lead:1.25rem;
$small:0.8rem;

$blue : #20368c;
$darkgrey : #333;
$grey : #666;
$lightgrey : #ccc;
$verylightgrey : #d6d6d6;
$greybg:#F9F9F9;

$hamburger-hover-opacity: 1 !default;
$hamburger-layer-spacing: 5px !default;
$hamburger-layer-width: 28px !default;
$hamburger-layer-height: 3px !default;
$hamburger-layer-border-radius: 0px !default;