.btn{
    border-radius:0px;
    font-size:$h5;
    // &.btn-default{
    //     background-color:#f1f1f1;
    //     border-color:#f1f1f1;
    //     color:#1E338E;
    // }
    &.btn-primary{
        background-color:$blue;
        border:2px solid $blue;
        color:#fff;
        &:hover {
            background-color: #fff;
            color:$blue;
            border-color: $blue;
        }
    }
}
// .close-news-button,.btn-layer-toggle{
//     position:absolute;
//     right:61px;
//     top:0;
//     border-radius: 0;
//     border:0;
//     z-index: 1000;
//     display: block;
//     height:60px;
//     line-height: 40px;
//     padding:10px;
//     width:160px;
//     color:#20368c!important;
//     background-color:rgba(255, 255, 255, 0.7)!important;
//     @media screen and (min-width:768px) {
//         display:none;
//     }
//     .off,.on{
//         display:block;
//         width:140px;
//     }
//     .off{
//         transition: opacity 0.25s ease-in-out, height 0.01s ease-in-out 0.25s;
//         opacity: 0;
//         height:1px;
//     }
//     .on{
//         transition: opacity 0.25s ease-in-out, height 0.01s ease-in-out 0.25s;
//         height:60px;
//         opacity:1;
//     }
//     &:active{
//         color:#20368c;
//         background-color:rgba(255, 255, 255, 0.7);
//         &:hover{
//             color:#20368c;
//             background-color:rgba(255, 255, 255, 0.7);
//         }
//     }
//     &.active{
//         color:#20368c;
//         background-color:rgba(255, 255, 255, 0.7);
//         &:hover{
//             color:#20368c;
//             background-color:rgba(255, 255, 255, 0.7);
//         }
//         .off{
//             transition: opacity 0.25s ease-in-out 0.01s, height 0.01s ease-in-out;
//             height:60px;
//             opacity:1;
//         }
//         .on{
//             transition: opacity 0.25s ease-in-out 0.01s, height 0.01s ease-in-out;
//             opacity: 0;
//             height:0;
//         }
//     }

// }
// .close-news-button{
//     opacity: 0;
//     height:0;
//     transition: all 0.25s ease-in-out;
//     margin-right:1px;
// }
// .reverse{
//     .close-news-button{
//         opacity: 1;
//         height: auto;
//     }
// }