.form-control {
    border: 2px solid #ddd;
    border-radius: 0;
}

.btn {
    &.btn-default {
        background-color: white;
        border: 2px solid #ddd;
        text-transform: uppercase;
        @include breakpoint(lg) {
            padding: 10px 60px;
        }
        &:hover {
            background-color: #1E338E;
            border-color: #1E338E;
            color: #f1f1f1;
        }
    }
}