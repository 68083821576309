.section-heading {
   margin-bottom:10px;
}

p a, ul a,h1 a,h2 a,h3 a,h4 a,h5 a,h6 a {
   color:$blue;
   font-weight:bold;
   &:hover {
      color:$blue;
   }
}

.container {
   @include breakpoint(lg) {
      max-width:1040px;
   }
}

hr {
   border-top-color:$verylightgrey;
}