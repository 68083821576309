.page-title {
   .search-form {
      border:2px solid $verylightgrey;
      margin-top:15px;
      border-radius:4px;
   }
}

.result {
   padding-bottom:15px;
   margin-bottom:15px;
   border-bottom:1px solid $verylightgrey;
   display: block;
   &:last-of-type {
      border-bottom:0;
   }

   .result-img {
      width:160px;
   }
}