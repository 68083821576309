.image-text-block {
   padding:15px 0;
   @include breakpoint(lg) {
      padding:30px 0;
   }
   h1 {
      margin-bottom:10px;
   }
   p {
      margin-bottom: 10px;
   }
}