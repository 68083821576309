.hp-intro {
   padding:30px 0;
   @include breakpoint(lg) {
      padding:60px 0;
   }
}
.welcome-to-dca {
   max-width:260px;
   margin-bottom:10px;
}

.divisions {
   padding:30px 0;
   @include breakpoint(lg) {
      padding:60px 0;
   }
}