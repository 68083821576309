.news-img {
   margin:6px 0;
    @include breakpoint(lg) {
        margin:15px 0;
    }
}

.news-text {
   margin:6px 0;
    @include breakpoint(lg) {
        margin:15px 0;
    }
}