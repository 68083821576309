.project-list {
    margin-top:30px;
    @include breakpoint(lg) {
        margin-top:60px;
    }
}

.project-details {
    padding:15px 0;
    @include breakpoint(lg) {
        padding:30px 0;
    }
    h6 {
        @include breakpoint(md) {
            margin-bottom:5px;
        }
    }
}

.project-awards {
    text-align: center;
    padding:15px 0;
    background-color: $greybg;
    @include breakpoint(lg) {
        padding:30px 0;
    }
}
.project-content {
    margin:30px 0;
    @include breakpoint(lg) {
        margin:60px 0;
    }
    .section-title {
        margin-bottom:15px;
    }
}

.project-stage {
    margin:6px 0;
    @include breakpoint(lg) {
        margin:15px 0;
    }
}

.section-images {
    position: relative;
    margin:6px 0;
    @include breakpoint(lg) {
        margin:15px -7px;
    }

    img {
        width:100%;
    }
    .image-portrait {
        width: 100%;
        text-align: center;
        display: block;
        img {
            max-height: 800px;
        }
    }
    .image {
        position: relative;
        padding:5px;
        @include breakpoint(lg) {
            padding:7px;
        }

        .enlarge {
            width: 20px;
            height: 20px;
            position: absolute;
            bottom: 15px;
            right: 15px;
            z-index: 10;

            background: url(/images/expand.svg) center no-repeat;
            background-size: contain;
        }
    }
}

.related-projects {
    hr {
        margin:30px 0;
        @include breakpoint(lg) {
            margin:60px 0;
        }
    }
    .related-projects-title {
        margin-bottom:15px;
    }
}

.call-to-action {
    text-align: center;
    hr {
        margin:30px 0;
        @include breakpoint(lg) {
            margin:60px 0;
        }
    }
    h1 {
        margin-bottom:15px;
    }
}


.lb-data .lb-close {
    position: fixed;
    top:10px;
}